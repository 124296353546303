.container {
    margin: auto;
    width: -moz-fit-content;
    width: fit-content;
}

div.ball {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-left: 20px;
    display: inline-block;
    position: relative;
    animation: jump 1s infinite;
}

div.ball1 {
    background-color: #2281E3;
}

div.ball2 {
    background-color: #2281E3;
    animation-delay: 0.15s;
}

div.ball3 {
    background-color: #2281E3;
    animation-delay: 0.3s;
}

div.ball4 {
    background-color: #2281E3;
    animation-delay: 0.45s;
}

div.ball5 {
    background-color: #2281E3;
    animation-delay: 0.6s;
    margin-right: 20px;
}

@keyframes jump {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-20px);
    }

    100% {
        transform: translateY(0);
    }
}